import React, { useState, useRef, useEffect } from 'react';
import './App.css';

function App() {
  // Initialize the toggle state for dark mode
  const [toggleState, setToggleState] = useState(false);
  // Audio element reference
  const audioRef = useRef(null);
  // Canvas element reference for Matrix effect
  const canvasRef = useRef(null);

  // Initialize column positions with negative random values to start off-screen
  const columns = useRef(Array(300).fill(0).map(() => -Math.random() * 2000));
  
  // Initialize speeds for each column with random values
  const speeds = useRef(Array(300).fill(0).map(() => Math.random() * 5 + 5));

  // Function to handle the toggle for dark mode
  const handleChange = () => {
    setToggleState(!toggleState);
  };

  // Function to draw the Matrix effect
  const drawMatrix = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
  
    // Clear existing canvas before drawing new text
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    // Draw green characters
    ctx.fillStyle = 'rgba(0, 255, 0, 1)';
    ctx.font = '20px monospace';
  
    columns.current.forEach((y, index) => {
      const numSymbols = 100;
      for (let i = 0; i < numSymbols; i++) {
        const char = String.fromCharCode(0x30A0 + Math.random() * 33);
        const symbolY = y + i * 20;
        const x = index * 20;
        if (symbolY > 0 && symbolY < canvas.height) {
          ctx.fillText(char, x, symbolY);
        }
      }
      if (y > canvas.height + numSymbols * 20) {
        columns.current[index] = -numSymbols * 20;
      } else {  
        // Update position based on individual speed
        columns.current[index] = y + speeds.current[index];
      }
    });
  };   

  // Automatically start as paused and muted when the component mounts
  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.muted = true;
    }
  }, []);

  // Function to play audio when in dark mode and pause when not
  useEffect(() => {
    if (toggleState) {
      audioRef.current.muted = false;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.muted = true;
    }
  }, [toggleState]);

  // Side effect to handle the Matrix effect
  useEffect(() => {
    let matrixInterval;

    if (toggleState) {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      matrixInterval = setInterval(drawMatrix, 200);
    } else {
      clearInterval(matrixInterval);

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    return () => clearInterval(matrixInterval);
  }, [toggleState]);

  return (
    <div className={`App ${toggleState ? 'dark-mode' : ''}`}>
      <canvas ref={canvasRef} id="matrixCanvas"></canvas>
      <div className="container" style={{ position: 'relative', zIndex: 1 }}>
        <div className="left">
          <div className="image-box">
            <img className="responsive-image" src="/IMG_7010.JPG" alt="Benjamin B" />
          </div>
          <div className="toggle-container-mobile">
            <label className="toggle-switch">
              <input type="checkbox" checked={toggleState} onChange={handleChange} />
              <span className="toggle-slider round"></span>
            </label>
          </div>
        </div>
        <div className="right">
          <div className="toggle-container">
            <label className="toggle-switch">
              <input type="checkbox" checked={toggleState} onChange={handleChange} />
              <span className="toggle-slider round"></span>
            </label>
          </div>
          <div className="right-main">
            <div className="right-content">
              <h1>Hi, My Name Is</h1>
              <h1>Benjamin Pittenger</h1>
              <p>Entertainment. Tech.</p>
              <p>Real Estate.</p>
              <div className="buttons">
                <a href="https://www.linkedin.com/in/benjaminpittenger" target="_blank" rel="noopener noreferrer">
                  <button>Linkedin</button>
                </a>
                <a href="https://twitter.com/ben_pittenger" target="_blank" rel="noopener noreferrer">
                  <button>Twitter</button>
                </a>
                <a href="mailto:hello@benjaminpittenger.com">
                  <button>Contact Me</button>
                </a>
                <a href="https://calendly.com/benpittenger" target="_blank" rel="noopener noreferrer">
                  <button>Work With Me</button>
                </a>
              </div>
            </div>
          </div>
          <div className="footer">
            <p></p>
          </div>
        </div>
      </div>
      <audio ref={audioRef} loop muted>
        <source src="/price-of-freedom-33106.mp3" type="audio/mp3" />
      </audio>
    </div>
  );
}

export default App;